export const funeralDate: Date = new Date('2024-05-11T00:00:00')
export const liveStreamLink: string = 'https://www.youtube.com/embed/1bLLuXcTTQI?si=et4MDfgLbIC4Ejwd'
export const MAX_TRIBUTE_CHARACTERS: number = 3000

export const isBeforeServiceDate = () => {
    const now = new Date()
    return funeralDate > now
}

const calculateHeaderHeight = (): number => {
    const menuDiv = document.querySelector('.page-menu')
    const menuDivPosition = menuDiv?.getBoundingClientRect().top
    const isMiniHeader = menuDivPosition !== undefined && menuDivPosition <= 300

    const portraitContainer = document.getElementById('portrait-container')
    const portraitContainerHeight = portraitContainer ? portraitContainer.offsetHeight : 0
    const menuContainer = document.getElementById('menu-container')
    const menuContainerHeight = menuContainer ? menuContainer.offsetHeight : 0
    return portraitContainerHeight + menuContainerHeight - (isMiniHeader ? 0 : 100)
}

export const scrollToTarget = (targetRefs: React.MutableRefObject<{[p: string]: HTMLDivElement | null}>, targetId: string) => {
    const targetElement = targetRefs.current[targetId]
    if (targetElement) {
        const headerHeight = calculateHeaderHeight()
        const scrollPosition = targetElement.offsetTop - headerHeight
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
        })
    }
}

export interface Quote {
    text: string,
    source: string,
}

export const randomizeQuote = (): Quote => {
    const quotes: Quote[] = [
        {text: 'Blessed are those who mourn, For they shall be comforted.', source: 'Matthew 5:4'},
        {text: 'Come to Me, all you who labor and are heavy laden, and I will give you rest.', source: 'Matthew 11:28'},
        {text: 'The Lord is close to the brokenhearted; he rescues those whose spirits are crushed.', source: 'Psalms 34:18'},
        {text: 'Casting all your care upon Him, for He cares for you.', source: '1 Peter 5:7'},
        {text: 'I have fought the good fight, I have finished the race, I have kept the faith.', source: '2 Timothy 4:7'},
        {text: 'Trust in the Lord with all your heart, And lean not on your own understanding; In all your ways acknowledge Him, And He shall direct your paths.', source: 'Proverbs 3:5-6'},
    ]

    // Randomizing the selection
    const randomIndex = Math.floor(Math.random() * quotes.length)
    return quotes[randomIndex]
}