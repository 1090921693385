import React from 'react'
import './Obituary.scss'

interface IObituaryProps {
    id: string,
}

export const Obituary: React.FC<IObituaryProps> = ({
    id,
}: IObituaryProps) => {

    return (
        <section id={id} className='capitalized-first-letter'>
            <h2>Obituary</h2>
            <p>Ethlyn Flax-George was born to Pearl Rosabel and Darwin Flax on the 26th of April 1943 in Road Town Tortola. She is survived by her husband Roosevelt George and her three children, Janice, Greg and Michael, her siblings, grandchildren, nieces and nephews, and family members and friends too numerous to mention.</p>
            <p>Ethlyn grew up in Road Town, Tortola, British Virgin Island. She attended the Anglican school before becoming a secondary student under the tutelage of Principal Carlisle Scott at the BVI High School. She was quite keen in her studies and ranked very high in her Cambridge Examinations. After graduation from high school, Ethlyn was employed by the Government as a typist. In her role, she was required to type the historic Mary Proudfoot report on the status of the BVI.</p>
            <p>Ethlyn was progressive and always endeavored to improve upon her professional skills. During her tenure in the government, she held various positions, including that of a telephone operator and a secretary. She left her government position and started working as a secretary at Little Dix Bay Hotel which had recently opened at the time. From there she was employed by Clarence Christian Ltd as a secretary. Her next job took her to the Bank of Nova Scotia where she worked herself up the ladder to being a senior officer within the Operations Department. She left the bank for greener fields and became a secretary at Harney Westwood and Riegels, a law firm in the BVI. When one of the lawyers at Harney Westwood and Riegels decided to start his own law firm as Smith Hughes and Raworth, he offered her a position as a paralegal to which she accepted and worked for several years until she retired at the age of 50 years.</p>
            <p>In her retirement, Ethlyn started her own company, Corporate Mailing Services, and successfully ran it in addition to her other businesses - Elegant Lady (a female clothing store), and Beacon Bible Bookstore. Ethlyn also managed Greenbank Estate Villas and played a pivotal role in its advertising efforts, which led to the accommodation of numerous guests over the years, including royalty and celebrities visiting the territory.</p>
            <p>At the age of 12, her view of life changed when she embraced the message of eternal salvation. She realized that her decision to live a Christian life would call for many sacrifices of which she willingly accepted. She often quoted the words of the hymn, "I have made my Choice Forever" and each time she did; it was a renewed affirmation of her choice. Almost all of her youth was spent teaching Sunday School classes across Tortola and Virgin Gorda; preaching, witnessing about her faith, and serving in whatever capacity that was needed by the church. Although originally an Angelican, Ethlyn chose to join the Church of God (Holiness) where she worked tirelessly with Dr. Rev. H C Dixon and Sis Dixon to developing the church and its activities, thereby gaining the status of foundation member for the Church of God (Holiness) Eastern Caribbean.</p>
            <p>On April 8th, 2024 she returned to heaven where she will forever be rejoicing with those gone on before her. Ethlyn Flax-George was an amazing wife, a stellar mother and a devout child of God; she will be remembered forever.</p>
        </section>
    )
}

export default Obituary