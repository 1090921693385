import React from 'react'
import ResponsiveSVG from './ResponsiveSVG'
import FamilyTreeSvg from '../images/family-tree.svg'
import './FamilyTree.scss'

interface IFamilyTreeProps {
    id?: string
}

const FamilyTree: React.FC<IFamilyTreeProps> = ({
    id = 'familyTree',
}: IFamilyTreeProps) => {
    return (
        <section id={id}>
            <h2>Family Tree</h2>
            <div className='family-tree'>
                <ResponsiveSVG src={FamilyTreeSvg} />
            </div>
        </section>
    )
}

export default FamilyTree