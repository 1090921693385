import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {isBeforeServiceDate, liveStreamLink} from '../utils/CommonUtils'
import './Service.scss'

interface IServiceProps {
    id: string,
}

export const Service: React.FC<IServiceProps> = ({
    id,
}: IServiceProps) => {

    return (
        <section id={id} className='service'>
            <h2>Funeral Service</h2>
            <div>
                Ethlyn Flax-George of Hodge’s Creek, Tortola was received into the arms of Jesus on Monday, 8th of April 2024. Ethlyn is survived by her...
                <div className='table'>
                    <div className='row'>
                        <div className='cell'>Husband:</div>
                        <div className='cell'>Roosevelt George</div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Three Children:
                        </div>
                        <div className='cell'>
                            Janice George-Harris, Gregory George and Michael George
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Son-in-law:
                        </div>
                        <div className='cell'>
                            Sheldon Harris
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Daughters-in-law:
                        </div>
                        <div className='cell'>
                            Eugenie George and Aquilina George
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Three Grand Children:
                        </div>
                        <div className='cell'>
                            Imani George, Malik George and Jasmine George
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Three Siblings:
                        </div>
                        <div className='cell'>
                            Sydney Flax of St. Thomas, Daisy Simmonds of Tortola and Wanda Claxton of the United States
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            In-laws:
                        </div>
                        <div className='cell'>
                            Bernice Lennard, Marcia Belmar, Maxwell George, and Roycie George
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Nieces and Nephews:
                        </div>
                        <div className='cell'>
                            Cynthia Campbell, Pearl Flax, Cheryl Simmonds-Molyneaux, Lisa Simmonds-Scatliffe, Dean Simmonds, Carleen Simmonds-Parsons, Ray Eloi, Gary Eloi, and Melissa Eloi
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Special Friends:
                        </div>
                        <div className='cell'>
                            Isis Malone, Anna Gracia Joseph, and Doris Rhymer
                        </div>
                    </div>
                    <div className='row'>
                        <div className='cell'>
                            Church Family:
                        </div>
                        <div className='cell'>
                            The brethren at the Church of God (Holiness)
                        </div>
                    </div>
                </div>
                <br/>...and her many other dear friends both local and abroad.
            </div>
            <p>
                The viewing of the late Ethlyn Flax-George will be on <span className='highlight'>May 10th, 2024 at Davis Funeral Home, starting at 2pm</span>. The closed casket funeral service will be held on <span className='highlight'>May 11, 2024 at the East End Church of God Holiness at 10am</span>.
            </p>

            <div>
                {isBeforeServiceDate() ? (
                    <div className={'virtual-event'}>
                        <p>
                            <span className={'note'}>Note: </span>
                            The event will be live-streamed. The
                            <span className={'youtube-label'}><FontAwesomeIcon icon={faYoutube} className={'youtube-link-icon'} />YouTube </span>
                            link will be made available here on the day of the funeral.
                        </p>
                    </div>
                ) : (
                    <div className={'virtual-event'}>
                        <p>
                            The event can be viewed live below or by clicking the following link...
                            <a href={liveStreamLink} target='_blank' rel='noreferrer' >
                                <FontAwesomeIcon icon={faYoutube} className={'youtube-link-icon'} />
                                Watch Live Event
                            </a>
                        </p>
                        <div className={'live-stream'}>
                            <iframe src={liveStreamLink}
                                    title='Funeral of Ethlyn Flax-George'
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default Service