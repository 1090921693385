import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { MAX_TRIBUTE_CHARACTERS } from '../utils/CommonUtils'
import {API_URL, AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_CLIENT_SECRET, AUTH0_DOMAIN} from '../types'
import { Growl } from '../components/Growl'
import moment from 'moment'
import './Tributes.scss'

interface ITributesProps {
    id?: string,
}

interface ITribute {
    fullName: string,
    message: string,
    createdDate: Date,
}

interface DecodedToken {
    iss: string
    aud: string
    exp: number
}

export const Tributes: React.FC<ITributesProps> = ({
    id = 'tributes',
}: ITributesProps) => {
    const initialFormData = {
        fullName: '',
        email: '',
        message: '',
    }

    /*************************************************************
     ** Use State Definitions
     **/
    const [accessToken, setAccessToken] = useState<string|null>(null)
    const [tributes, setTributes] = useState<ITribute[]>()
    const [showTributeForm, setShowTributeForm] = useState(false)
    const [charCount, setCharCount] = useState(0)
    const [formData, setFormData] = useState(initialFormData)
    const [growlMessage, setGrowlMessage] = useState<string>('')
    const [growlInstance, setGrowlInstance] = useState<number>(Math.floor(Math.random() * 1000))

    /*************************************************************
     ** Use Ref Definitions
     **/
    const contributionFormRef = useRef<HTMLFormElement>(null)
    const nameInputRef = useRef<HTMLInputElement>(null)

    /*************************************************************
     ** Growl
     **/
    const growl = (message: string) => {
        if (message) {
            setGrowlMessage(message)
            setGrowlInstance(Math.floor(Math.random() * 1000))
        }
    }

    /*************************************************************
     ** Use Effects
     **/
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        const storedAccessToken = sessionStorage.getItem('accessToken')
        if (storedAccessToken) {
            const decodedAccessToken: DecodedToken = jwtDecode<DecodedToken>(storedAccessToken)
            const isTokenExpired = Date.now() >= decodedAccessToken.exp * 1000
            if (isTokenExpired) {
                //-- Access token is expired... fetch a new token
                fetchAccessToken()
            } else {
                //-- Use stored access token
                setAccessToken(storedAccessToken)
            }
        } else {
            //-- Access token is not stored... fetch a new token
            fetchAccessToken()
        }
    }, [])

    useEffect(() => {
        if (accessToken && !tributes) {
            retrieveTributes()
        }
    }, [accessToken])

    useEffect(() => {
        if (showTributeForm && contributionFormRef.current) {
            contributionFormRef.current.scrollIntoView({
                behavior: 'smooth',
            })
            if (nameInputRef.current) {
                nameInputRef.current.focus()
            }
        }
    }, [showTributeForm])

    /*************************************************************
     ** Form Interaction Functions
     **/
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        if (name === 'message' && value.length > MAX_TRIBUTE_CHARACTERS) {
            return
        }
        setFormData({ ...formData, [name]: value })
        if (name === 'message') {
            setCharCount(value.length)
        }
    }

    const handleShowTributeForm = () => {
        setShowTributeForm(true)
    }

    const handleResetTribute = () => {
        setShowTributeForm(false)
        setFormData(initialFormData)
        setCharCount(0)
    }

    /*************************************************************
     ** API Functions
     **/
    const fetchAccessToken = () => {
        fetch(`https://${AUTH0_DOMAIN}/oauth/token`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: `{"client_id":"${AUTH0_CLIENT_ID}","client_secret":"${AUTH0_CLIENT_SECRET}","audience":"${AUTH0_AUDIENCE}","grant_type":"client_credentials"}`
        })
            .then(response => response.json())
            .then(data => {
                if (data['access_token']) {
                    const generatedAccessToken = data['access_token']
                    sessionStorage.setItem('accessToken', generatedAccessToken)
                    setAccessToken(generatedAccessToken)
                }
                return data
            }).catch(error => {
            console.error('Error:', error)
        })
    }

    const retrieveTributes = () => {
        if (accessToken) {
            fetch(`${API_URL}/tributes`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${accessToken}`
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network not responding')
                    }
                    return response.json()
                })
                .then(data => {
                    const dataCopy: ITribute[] = [...data]
                    setTributes(dataCopy)
                }).catch(error => {
                console.error('Error:', error)
            })
        }
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formDataJson = JSON.stringify(formData)
        handleResetTribute()

        if (accessToken) {
            fetch(`${API_URL}/tributes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${accessToken}`
                },
                body: formDataJson
            })
                .then(response => {
                    if (!response.ok) {
                        growl('Error submitting your tribute')
                        throw new Error('Error submitting tribute')
                    } else {
                        growl('Thanks for your contribution')
                    }
                    return response
                }).catch(error => {
                console.error('Error:', error)
            })
        } else {
            growl('Unable to submit your tribute')
        }
    }

    return (
        <section id={id}>
            <h2>Tributes</h2>
            <p>Share your memories, stories, and tributes, celebrating the life of Ethlyn Flax-George using the form below (submissions will be published within 48 hours). Alternatively, you may email your tributes directly to <a className={'email-link'} href={'mailto:tributes@ethlyngeorge.com'}>tributes@ethlyngeorge.com</a>.</p>

            <button onClick={() => {handleShowTributeForm()}} type={'button'} className={'primary-button'}>Share a Tribute</button>

            {
                tributes && tributes.map((tribute, idx) => {
                    return (
                        <div className={'tribute-container'} key={idx}>
                            <div className={'tribute-date'}>{moment.utc(tribute.createdDate).local().format('LL')}</div>
                            <div className={'tribute-message'}>{tribute.message}</div>
                            <div className={'tribute-fullName'}>{tribute.fullName}</div>
                        </div>
                    )
                })
            }

            {tributes && tributes.length > 2 && (
                <button onClick={() => {handleShowTributeForm()}} type={'button'} className={'primary-button'}>Share a Tribute</button>
            )}


            {(showTributeForm) && (
                <form onSubmit={handleSubmit} className={'contribution-form'} ref={contributionFormRef}>
                    <div className={'row'}>
                        <label>
                            Your Full Name<span className={'required'}>*</span>:
                            <input type='text' name='fullName' ref={nameInputRef} value={formData.fullName} onChange={handleChange} required maxLength={200} />
                        </label>
                        <label>
                            Your Email Address:
                            <input type='email' name='email' value={formData.email} onChange={handleChange} maxLength={100} />
                        </label>
                    </div>
                    <div className={'message-wrapper'}>
                        <label>
                            Your Tribute<span className={'required'}>*</span>:
                            <textarea name='message' className={'message'} value={formData.message} onChange={handleChange} required />
                        </label>
                        <div className={'characters-remaining'}>Characters remaining: {MAX_TRIBUTE_CHARACTERS - charCount}</div>
                    </div>
                    <div>
                        <button type='submit' className={'primary-button button-spacer'}>Submit</button>
                        <button onClick={() => {handleResetTribute()}} type='button' className={'secondary-button'}>Cancel</button>
                    </div>
                </form>
            )}

            <Growl id={'growl'} message={growlMessage} instance={growlInstance} />
        </section>
    )
}

export default Tributes