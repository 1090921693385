import React, {useEffect, useState, useRef} from 'react'
import { Link, useLocation } from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBookOpen,
    faComment,
    faTimeline,
    faImages,
    faSitemap,
    faChurch,
} from '@fortawesome/free-solid-svg-icons'
import {isBeforeServiceDate, scrollToTarget} from '../utils/CommonUtils'
import './Layout.scss'

interface ILayout {
    id?: string
}

const Layout: React.FC<ILayout> = ({
    children
}) => {
    const [isPortraitMini, setIsPortraitMini] = useState<boolean>(false)
    const [isMenuClicked, setIsMenuClicked] = useState<boolean>(false)
    const [isServicePending, setIsServicePending] = useState<boolean>(false)

    /*************************************************
     ** INITIALIZE AND MINI PORTRAIT SCROLL HANDLER
     **/
    useEffect(() => {
        setIsServicePending(isBeforeServiceDate())

        const handleScroll = () => {
            const menuDiv = document.querySelector('.page-menu')
            const menuDivPosition = menuDiv?.getBoundingClientRect().top

            setIsPortraitMini(menuDivPosition !== undefined && menuDivPosition <= 300)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    /*************************************************
     ** TARGET SCROLL HANDLER
     **/
    const location = useLocation()
    const targetRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

    useEffect(() => {
        if (isMenuClicked) {
            const targetId = location.hash.slice(1)

            const targetIds = [
                'obituary',
                'service',
                'familyTree',
                'timeline',
                // 'photos',
            ]

            // Dynamically create refs for each target ID
            targetIds.forEach(id => {
                targetRefs["current"][id] = document.getElementById(id) as HTMLDivElement
            })
            window.setTimeout(() => {
                scrollToTarget(targetRefs, targetId)
                setIsMenuClicked(false)
            }, 1)
        }
    }, [isMenuClicked, location.hash])

    return (
        <div className='layout'>
            <header>
                <div className='banner-title'>Forever Remembered</div>
                {/*Contains a banner image*/}
            </header>
            <main>
                <div className='content-wrapper'>
                    <div id='portrait-container' className={`portrait-wrapper${isPortraitMini ? '-mini' : ''}`}>
                        <div className='portrait'>
                            <div className='portrait-container'>
                                <div className='img'></div>
                            </div>
                        </div>
                        <div className='portrait-title'>
                            {isPortraitMini ? (
                                <>
                                    <h1 className='name'>Ethlyn George</h1>
                                </>
                            ) : (
                                <>
                                    <h1 className='name'>Ethlyn George</h1>
                                    <div className='dates'>April 26, 1943 - April 8, 2024</div>
                                    <div className='location'>Hodge's Creek, Tortola, BVI</div>
                                </>
                            )}
                        </div>
                    </div>
                    <menu id='menu-container' className='page-menu' data-showhideitems='3'>
                        <ul>
                            <li>
                                <Link to='/#obituary' onClick={() => {setIsMenuClicked(true)}}>
                                    <FontAwesomeIcon icon={faBookOpen} className='menu-icon' />
                                    Obituary
                                </Link>
                            </li>
                            {isServicePending && (
                                <li>
                                    <Link to='/#service' onClick={() => {setIsMenuClicked(true)}}>
                                        <FontAwesomeIcon icon={faChurch} className='menu-icon' />
                                        Funeral Service
                                    </Link>
                                </li>
                            )}
                            <li>
                                <Link to='/#familyTree' onClick={() => {setIsMenuClicked(true)}}>
                                    <FontAwesomeIcon icon={faSitemap} className='menu-icon' />
                                    Family Tree
                                </Link>
                            </li>
                            <li>
                                <Link to='/#timeline' onClick={() => {setIsMenuClicked(true)}}>
                                    <FontAwesomeIcon icon={faTimeline} className='menu-icon' />
                                    Timeline
                                </Link>
                            </li>
                            <li>
                                <Link to='/tributes' onClick={() => {setIsMenuClicked(true)}}>
                                    <FontAwesomeIcon icon={faComment} className='menu-icon' />
                                    Tributes
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to='/photos' onClick={() => {setIsMenuClicked(true)}}>*/}
                            {/*        <FontAwesomeIcon icon={faImages} className='menu-icon' />*/}
                            {/*        Photo Gallery*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                        </ul>
                    </menu>
                    <article>
                        {children}
                    </article>
                </div>
            </main>
            <footer>
                Designed with Love by Fin Fin - Rest in Heavenly Peace
            </footer>
        </div>
    )
}

export default Layout