import React from 'react'

// Define the props for the ResponsiveSVG component
interface ResponsiveSVGProps {
    src: string
}

const ResponsiveSVG: React.FC<ResponsiveSVGProps> = ({ src }) => {
    const svgSize = {
        width: '100%',
    };

    return (
        <svg width={svgSize.width} viewBox="0 0 2 1">
            <image href={src} width="100%" />
        </svg>
    );
};

export default ResponsiveSVG